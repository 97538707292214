import Keycloak from 'keycloak-js';
import { PagesPaths } from '@enums/PagesPathsEnum';
import { Roles } from '@src/base/roles';

let securityConfig = {
  url: window.__RUNTIME_CONFIG__.KEYCLOAK_AUTH_SERVER_URL ?? '',
  realm: window.__RUNTIME_CONFIG__.KEYCLOAK_REALM ?? '',
  clientId: window.__RUNTIME_CONFIG__.KEYCLOAK_RESOURCE ?? '',
};

if (import.meta.env?.VITE_ENV === 'staging') {
  if (
    import.meta.env?.VITE_KEYCLOAK_AUTH_SERVER_URL &&
    import.meta.env?.VITE_KEYCLOAK_REALM &&
    import.meta.env?.VITE_KEYCLOAK_RESOURCE
  ) {
    securityConfig = {
      url: import.meta.env.VITE_KEYCLOAK_AUTH_SERVER_URL,
      realm: import.meta.env.VITE_KEYCLOAK_REALM,
      clientId: import.meta.env.VITE_KEYCLOAK_RESOURCE,
    };
  }
}

const HOTJAR_STORAGE_KEY = 'cookieNotice';
const TOKEN_STORAGE_KEY = 'kc_token';
const REFRESH_TOKEN_STORAGE_KEY = 'kc_refreshToken';
const _kc = new Keycloak(securityConfig);

class KeycloakAuthService {
  private static instance: KeycloakAuthService;
  private initialized: boolean = false;
  private initializationPromise: Promise<void> | null = null;
  public TOKEN_STORAGE_KEY = 'kc_token';
  public REFRESH_TOKEN_STORAGE_KEY = 'kc_refreshToken';

  private constructor() {}

  public static getInstance(): KeycloakAuthService {
    if (!KeycloakAuthService.instance) {
      KeycloakAuthService.instance = new KeycloakAuthService();
    }
    return KeycloakAuthService.instance;
  }

  public initKeycloak = async (onAuthenticatedCallback: () => void): Promise<void> => {
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = this.initializeKeycloak(onAuthenticatedCallback);
    return this.initializationPromise;
  };

  private initializeKeycloak = async (onAuthenticatedCallback: () => void): Promise<void> => {
    const token = localStorage[TOKEN_STORAGE_KEY];
    const refreshToken = localStorage[REFRESH_TOKEN_STORAGE_KEY];

    try {
      const authenticated = await _kc.init({
        onLoad: 'login-required',
        token,
        refreshToken,
        checkLoginIframe: false,
        pkceMethod: 'S256',
      });

      if (!authenticated) {
        throw new Error('Failed to authenticate');
      }

      this.updateLocalTokens();
      this.initialized = true;
      onAuthenticatedCallback();
    } catch (err) {
      this.logout();
      throw err;
    }
  };

  public login = _kc.login;

  public logout = (): void => {
    const cookieNoticeValue = localStorage.getItem(HOTJAR_STORAGE_KEY);
    localStorage.clear();
    cookieNoticeValue !== null && localStorage.setItem(HOTJAR_STORAGE_KEY, cookieNoticeValue);
    sessionStorage.clear();
    window.history.replaceState(null, '', PagesPaths.HOME);
    _kc.logout();
  };

  public isLoggedIn = (): boolean => !!_kc.token;

  public getToken = async (): Promise<string | undefined> => {
    try {
      await _kc.updateToken(30);
      this.updateLocalTokens();
      return _kc.token;
    } catch (error) {
      this.logout();
      throw error;
    }
  };

  private updateLocalTokens(): void {
    if (_kc.token != null) {
      localStorage.setItem(TOKEN_STORAGE_KEY, _kc.token);
    }
    if (_kc.refreshToken != null) {
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, _kc.refreshToken);
    }
  }

  public getUsername = (): string | undefined => _kc.profile?.username;

  public hasRole = (role: Roles): boolean => {
    return _kc.hasRealmRole(role);
  };

  public updateToken = async (): Promise<void> => {
    _kc.updateToken(2000000);
    this.updateLocalTokens();
  };

  public getRoles = (): string[] | undefined => {
    return _kc.realmAccess?.roles;
  };

  public isInitialized = (): boolean => {
    return this.initialized;
  };
}

const KeyCloakAuth = KeycloakAuthService.getInstance();

export default KeyCloakAuth;
