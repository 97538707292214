import React, { useCallback, useEffect } from 'react';
import { ActionsLayout, Form, getFieldProps, Label, Inputs, Button } from '@crx-dev/hss-react-components';
import { useFormik } from 'formik';
import styles from '@components/LocumDetails/LocumDetailsForm.module.css';
import { MultiSiteDetailsSchema } from './MultiSiteDetails.constants';
import KeyCloakAuth from '@security/KeyCloakAuth';
import { MultiSiteDetailsFormFields } from '@src/models/common/MultiSiteDetails';
import useApiRequestNew from '@src/common/hooks/useApiRequestNew';
import { parseJwt } from '@src/utils/TokenHelper';
import { UPDATE_USER_ATTRIBUTES_STORE_ID } from '@src/api/urls/UserUrls';
import { PagesPaths } from '@enums/PagesPathsEnum';
import { useNavigate } from 'react-router-dom-v6';
import { useApplicationState } from '@context/ApplicationState';
import { GlobalState } from '@models/common/GlobalStateType';

const MultiSiteDetailsForm = () => {
  const [, setUserAttributeAction] = useApiRequestNew<string>('PUT', UPDATE_USER_ATTRIBUTES_STORE_ID, String);
  const navigate = useNavigate();
  const { setGlobalState } = useApplicationState();
  const updateUserDetails = useCallback(
    (storeId: string) => {
      const token = localStorage.getItem(KeyCloakAuth.TOKEN_STORAGE_KEY);
      if (token) {
        const actualObject = parseJwt(token);
        const newGetUserUrl = UPDATE_USER_ATTRIBUTES_STORE_ID.replace('{userId}', actualObject.sub).replace(
          '{storeId}',
          storeId,
        );
        setUserAttributeAction.setUrl(newGetUserUrl);
        setUserAttributeAction
          .makePromiseRequest(newGetUserUrl)
          .then(() => {
            KeyCloakAuth.updateToken().then(() => {
              // @ts-expect-error to be solved in the implementation itself
              setGlobalState((cur: any) => {
                const newGlobal = new GlobalState();
                newGlobal.user = { ...cur.user, storeId: storeId };
                return newGlobal;
              });
              sessionStorage.setItem('storeId', storeId);
              window.location.reload();
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    [setGlobalState, setUserAttributeAction],
  );

  const handleMultiSiteUserChange = useCallback(
    (multiSiteUser: MultiSiteDetailsFormFields) => {
      updateUserDetails(multiSiteUser.storeId);
    },
    [updateUserDetails],
  );

  const formik = useFormik<MultiSiteDetailsFormFields>({
    initialValues: {
      storeId: '',
    },
    validationSchema: MultiSiteDetailsSchema,
    onSubmit: handleMultiSiteUserChange,
  });

  useEffect(() => {
    if (sessionStorage.getItem('storeId')) {
      navigate(PagesPaths.HOME, { replace: true });
    }
  }, [navigate]);

  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <Form onSubmit={formik.handleSubmit}>
          <div className={styles.title}>Multi Site Details</div>
          <Label htmlFor="storeId" label="Please enter your details" />

          <Inputs.Text maxLength={50} {...getFieldProps(formik, 'storeId')} placeholder="Store Id" />

          <ActionsLayout layout="inline">
            {/*@ts-expect-error to be solved by storybook*/}
            <Button variant="tertiary" id="formCancel_btn" onClick={() => KeyCloakAuth.logout()}>
              Cancel
            </Button>
            {/*@ts-expect-error to be solved by storybook*/}
            <Button variant="primary" id="save" type="submit">
              Continue
            </Button>
          </ActionsLayout>
        </Form>
      </div>
    </div>
  );
};

export default MultiSiteDetailsForm;
