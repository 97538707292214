import React, { useEffect, useState, useCallback, useRef } from 'react';
import CountDown from 'react-countdown';
import { durationBeforeTimeoutWarning, warningDuration, beforeTokenExpire } from './IdleTimeHandler.constant';
import { parseJwt } from '@utils/TokenHelper';
import KeyCloakAuth from '@security/KeyCloakAuth';
import { Modal, PrimaryButton, TertiaryButton, FlexLayout, Divider } from '@crx-dev/hss-react-components';
import { fromUnixTime, differenceInMilliseconds } from 'date-fns';

let timeoutId: number;
let renewTokenTimeoutId: number;
const IdleTimeHandler = () => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const tokenTimeout = useRef(0);

  //#region idle time
  const resetTimer = useCallback(() => {
    window.clearTimeout(timeoutId);
    startTimer();
  }, []);

  const startTimer = () => {
    // window.setTimeout returns an Id that can be used to start and stop a timer
    timeoutId = window.setTimeout(doWhenIdle, durationBeforeTimeoutWarning);
  };

  const doWhenIdle = () => {
    setShowWarningModal(true);
  };

  const setupTimers = () => {
    //this events that detect the idle of the user
    document.addEventListener('mousemove', resetTimer, false);
  };
  //#endregion

  //#region Token
  const renewToken = () => {
    KeyCloakAuth.getToken()
      .then(() => {
        setTokenTimeOut();
      })
      .catch(error => {
        console.log(error);
        handleLogout();
      });
  };
  const setTokenTimeOut = () => {
    try {
      const token = localStorage.getItem(KeyCloakAuth.REFRESH_TOKEN_STORAGE_KEY);
      if (token) {
        const actualObject = parseJwt(token);
        tokenTimeout.current = actualObject.exp;
        window.clearTimeout(renewTokenTimeoutId);

        const tokenExpireDate = fromUnixTime(tokenTimeout.current);
        const diffMS = differenceInMilliseconds(tokenExpireDate, new Date());

        renewTokenTimeoutId = window.setTimeout(renewToken, diffMS - beforeTokenExpire);

        // If you need the logging back:
        // console.log(`The token is set to be renewed before ${format(tokenExpireDate, 'hh:mm:ss aa')} with ${beforeTokenExpire/1000} seconds`);
      } else {
        KeyCloakAuth.logout(); // if no token log out
      }
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  useEffect(() => {
    setTokenTimeOut();
    setupTimers();
    startTimer();
  }, []);

  const renderer = ({ minutes, seconds }: any) => {
    return (
      <span>
        {' '}
        0{minutes}:{seconds < 10 ? '0' + seconds : seconds} {minutes > 0 ? ' Minutes' : ' Seconds'}
      </span>
    );
  };

  //#region Event Handlers
  const handleLogout = () => {
    document.removeEventListener('mousemove', resetTimer, false);
    window.clearTimeout(renewTokenTimeoutId);
    window.clearTimeout(timeoutId);
    KeyCloakAuth.logout();
  };
  const handleContinue = () => {
    setShowWarningModal(false);
    setupTimers();
  };
  //#endregion
  return (
    <>
      {showWarningModal ? (
        <Modal open={true} onCancel={() => {}} title="You're about to be signed out" width={550}>
          <>
            <div style={{ marginBottom: 100 }}>
              <p style={{ display: 'inline-block' }}>
                {' '}
                For security reasons, you will be signed out of this service in{' '}
              </p>
              <b>
                <CountDown
                  date={new Date(Date.now() + warningDuration)}
                  renderer={renderer}
                  onComplete={handleLogout}
                />
              </b>
            </div>
            <Divider />
            <FlexLayout justifyContent={'space-between'} marginBottom={10}>
              <TertiaryButton id="signOut-btn" onClick={handleLogout}>
                {' '}
                SIGN OUT NOW
              </TertiaryButton>
              <PrimaryButton id="staySignedIn_btn" onClick={handleContinue}>
                {' '}
                STAY SIGNED IN
              </PrimaryButton>
            </FlexLayout>
          </>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default IdleTimeHandler;
