export enum ValidationErrorsMsg {
  REQUIRED_MESSAGE = 'Required',
  MIN_2_CHAR_MESSAGE = '*Please enter at least 2 characters',
  INVALID_MOBILE_MESSAGE = 'please enter a valid mobile number',
  INVALID_EMAIL_FORMAT_MESSAGE = 'Please enter correct email format',
  MAX_15_CHAR_MESSAGE = 'Maximum length is 15 characters',
  MAX_254_CHAR_MESSAGE = 'Maximum length is 254 characters',
  INVALID_DOB_MESSAGE = 'Please enter a valid date of birth',
  FUTURE_DATE_MESSAGE = 'Date must not be in the future',
  VALID_POSTCODE_MESSAGE = 'Please enter a valid postcode format',
  PREDEFINED_VALUES = 'This must be one of the predefined values',
  MAX_LENGTH_1000_MESSAGE = 'Message must not be more than 1000 characters long.',
  MAX_LENGTH_100_MESSAGE = 'Message must not be more than 100 characters long.',
  FUTURE_DATE = 'Date cannot be in the future',
  SPECIAL_CHARACTERS = 'No special characters allowed',
  VALID_GPHC_NUMBER = 'Please enter a valid GPHC number',
  BLANK_FIELD = 'Field cannot be blank',
}
