export class User {
  id: number = -1;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  userName: string = '';
  gphc: string = '';
  roleId: number = -1;
  pharmacist: boolean = false;
  clinicianRole: string = '';
  storeId: string | null = null;
}
