import { phone } from './phone';

export class PharmacyStore {
  id: string = '';
  groupId: string = '';
  odsCode: string = '';
  name: string = '';
  phones = new Array<phone>();
  city: string = '';
  postalCode: string = '';
  address = new Array<string>();
  postCode: string = '';
  customerNumber: string = '';
  email: string = '';
  mhsId: string = '';
  serviceIds = new Array<string>();
}
