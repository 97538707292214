import React, { Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '@common/style/ReactToastifyOverride.css';
import { RouterProvider } from 'react-router-dom-v6';
import { LoadingSpinner } from '@crx-dev/hss-react-components';
import createRoutes from '@common/routes';

const router = createRoutes();

const App: React.FC = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
export default App;
