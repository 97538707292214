import React, { Component, ErrorInfo } from 'react';
import { Page, Button, Tag, Typography, Card, CardBody } from '@crx-dev/hss-react-components';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Page>
          <Card background="StalerWhite">
            <CardBody>
              <div style={{ textAlign: 'center', margin: '50px 0' }}>
                <Tag color="amber-50">Status Code 500</Tag>
                <Typography tag="h1" size="xxl" weight="bold" mt="m" mb="m">
                  Something went wrong
                </Typography>
                <Typography tag="p" size="m" mt="none" mb="m">
                  An unexpected error occurred, if this problem persists please contact support.
                </Typography>
                {/*@ts-expect-error to be solved by storybook*/}
                <Button onClick={() => window.location.replace('/')} variant="tertiary">
                  Go to Homepage
                </Button>
              </div>
            </CardBody>
          </Card>
        </Page>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
