import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom-v6';
import { LoadingSpinner } from '@crx-dev/hss-react-components';
import { PagesPaths } from '@enums/PagesPathsEnum';
import { Roles } from '@src/base/roles';
import KeyCloakAuth from '@security/KeyCloakAuth';

const WithRole = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  roles: Roles[],
): React.ComponentType<P> => {
  // Create a named component for better debugging
  const WithRoleComponent: React.FC<P> = props => {
    const [isChecking, setIsChecking] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
      const checkAccess = async () => {
        if (!KeyCloakAuth.isInitialized()) {
          try {
            await KeyCloakAuth.getToken();
          } catch (error) {
            console.error('Failed to refresh token:', error);
            setHasAccess(false);
            setIsChecking(false);
            return;
          }
        }

        const userRoles = KeyCloakAuth.getRoles();
        const hasRequiredRole = roles.some(role => userRoles?.includes(role));
        setHasAccess(hasRequiredRole);
        setIsChecking(false);
      };

      checkAccess();
    }, []);

    if (isChecking) {
      return <LoadingSpinner />;
    }

    if (!hasAccess) {
      return <Navigate to={PagesPaths.HOME} replace />;
    }

    return <WrappedComponent {...props} />;
  };

  // Set display name for debugging
  WithRoleComponent.displayName = `WithRole(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithRoleComponent;
};

export default WithRole;
