import KeyCloakAuth from '@security/KeyCloakAuth';
import Hotjar from '@hotjar/browser';
import { CEGEDIM_USER } from '@src/base/roles';

const HOTJAR_VERSION = 6;

export const hotjarInit = () => {
  if (KeyCloakAuth.hasRole(CEGEDIM_USER)) {
    return;
  }

  if (import.meta.env.MODE === 'production') {
    Hotjar.init(+window.__RUNTIME_CONFIG__.HOTJAR_ID, HOTJAR_VERSION);
  }
};

export default hotjarInit;
