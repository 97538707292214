import React, { useState, useCallback } from 'react';
import KeyCloakAuth from '@security/KeyCloakAuth';
import LocumDetailsForm from '../LocumDetails/LocumDetailsForm';
import { LocomDetailsFormFields } from '@models/common/LocumDetails';
import { LOCUM } from '@src/base/roles';

let locumInfo: LocomDetailsFormFields | null = null;

export function getLocumInfo() {
  console.log('locumInfo: ', locumInfo);
  return locumInfo;
}

const InterceptLocumUser = ({ children }: any) => {
  const [locumUser, setLocumUser] = useState<LocomDetailsFormFields>();

  const handleLocumUserChange = useCallback((locumUser: LocomDetailsFormFields) => {
    // set the global const so that makeRequest has access to it
    locumInfo = locumUser;
    // update state to cause a re-render
    setLocumUser(locumUser);
  }, []);

  return (
    <>
      {KeyCloakAuth.hasRole(LOCUM) && !locumUser ? (
        <LocumDetailsForm onSubmit={handleLocumUserChange} />
      ) : (
        children
      )}
    </>
  );
};

export default InterceptLocumUser;
