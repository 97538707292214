import React from 'react';
import { AppContainer } from '@crx-dev/hss-react-components';
import {
  DmsPagesPaths,
  FluVaccinationPagesPaths,
  HcfPagesPaths,
  NmsPagesPaths,
  PagesPaths,
  PcsPagesPaths,
} from '@enums/PagesPathsEnum';
import KeyCloakAuth from '@security/KeyCloakAuth';
import { CPCS, NEW_CPCS, DMS, FLUVAC, HCF, NMS, PCS } from '@src/base/roles';
import IdleTimeHandler from '../IdleTimeHandler/IdleTimeHandler';
import { useNavigate, useLocation, matchPath } from 'react-router-dom-v6';

const HELP_URL = 'https://help.cegedim-healthcare.co.uk/Pharmacy_Services/Content/Home.htm';
const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navHelper = (to: string, exact = false) => ({
    to,
    isSelected: !!matchPath({ path: to, end: exact }, location.pathname),
    onClick: (to: string) => navigate(to),
  });

  const handleHelpUrl = () => {
    switch (location.pathname) {
      case NmsPagesPaths.NMS_EPISODE:
      case NmsPagesPaths.NEW_MEDICINE_SERVICE:
        return HELP_URL + '#chsid=nms';
      case HcfPagesPaths.HCF_EPISODE:
      case HcfPagesPaths.HCF_SERVICE:
        return HELP_URL + '#chsid=hypertension';
      case PcsPagesPaths.PCS_EPISODE:
      case PcsPagesPaths.PCS_SERVICE:
        return HELP_URL + '#chsid=contraception';
      case FluVaccinationPagesPaths.FLU_VACCINATION_EPISODE:
      case FluVaccinationPagesPaths.FLU_VACCINATION_SERVICE:
        return HELP_URL + '#chsid=flu';
      case DmsPagesPaths.DMS_MANUAL_REFERRAL:
      case DmsPagesPaths.DMS_REFERRAL:
      case DmsPagesPaths.SESSION:
      case DmsPagesPaths.SUMMARY:
        return HELP_URL + '#chsid=dms';
      case PagesPaths.MINOR_ILLNESS:
      case PagesPaths.URGENT_SUPPLY:
      case PagesPaths.CLINICAL_PATHWAYS_WALK_IN_PAGE:
      case PagesPaths.MANUAL_REFERRAL:
      case PagesPaths.REFERRALS:
      case PagesPaths.REFERRAL_DETAILS:
      case PagesPaths.REFERRAL_SUMMARY:
        return HELP_URL + '#chsid=pharmacyfirst';
      default:
        return HELP_URL;
    }
  };
  return (
    <>
      <IdleTimeHandler />
      <AppContainer.NavItem label="Dashboard" {...navHelper(PagesPaths.HOME, true)} />
      {(KeyCloakAuth.hasRole(CPCS) || KeyCloakAuth.hasRole(NEW_CPCS)) && (
        <AppContainer.NavItem label="Pharmacy First" {...navHelper(PagesPaths.REFERRALS, true)} />
      )}
      {KeyCloakAuth.hasRole(DMS) && (
        <AppContainer.NavItem
          label="Discharge Medicines"
          isNew
          {...navHelper(PagesPaths.DISCHARGE_MEDICINES_REFERRALS, true)}
        />
      )}
      {KeyCloakAuth.hasRole(NMS) && (
        <AppContainer.NavItem label="NMS" {...navHelper(NmsPagesPaths.NEW_MEDICINE_SERVICE, true)} />
      )}
      {KeyCloakAuth.hasRole(FLUVAC) && (
        <AppContainer.NavItem
          label="Flu Vaccination"
          {...navHelper(FluVaccinationPagesPaths.FLU_VACCINATION_SERVICE, true)}
        />
      )}
      {KeyCloakAuth.hasRole(HCF) && (
        <AppContainer.NavItem
          label="Hypertension Case-Finding"
          {...navHelper(HcfPagesPaths.HCF_SERVICE, true)}
        />
      )}
      {KeyCloakAuth.hasRole(PCS) && (
        <AppContainer.NavItem label="Contraception" {...navHelper(PcsPagesPaths.PCS_SERVICE, true)} />
      )}
      <AppContainer.NavItem label="Help" to={handleHelpUrl()} external />
    </>
  );
};
export default SideBar;
