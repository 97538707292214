import React, { Suspense } from 'react';
import { LoadingSpinner } from '@crx-dev/hss-react-components';
import { Roles } from '@src/base/roles';
import WithRole from '@src/base/WithRole';
import ErrorBoundary from '@common/Error.page';

interface LazyRouteProps {
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  roles?: Roles[];
}

const LazyRoute: React.FC<LazyRouteProps> = ({ component: Component, roles }) => {
  const ComponentWithRoles = roles ? WithRole(Component, roles) : Component;

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingSpinner />}>
        <ComponentWithRoles />
      </Suspense>
    </ErrorBoundary>
  );
};

export default LazyRoute;
