export const CEGEDIM_USER = 'cegedim_user';
export const LOCUM = 'Locum';
export const STANDALONE = 'STANDALONE';
export const TESTER = 'Tester';
export const CPCS = 'CPCS';
export const MI_FHIR = 'MI FHIR';
export const NMS = 'NMS';
export const FLUVAC = 'FLUVAC';
export const HCF = 'HCF';
export const PCS = 'PCS';
export const PCS_NoMYS = 'PCS_NoMYS';
export const HCF_MYS = 'HCF_MYS';
export const PCS_GPCONNECT_UPDATE = 'PCS_GPCONNECT_UPDATE';
export const HCF_GPCONNECT_UPDATE = 'HCF_GPCONNECT_UPDATE';
//Controls whether Pharmacy First can update the GP details held for a patient using NHS GP Connect Update.
export const CPCS_GPCONNECT_UPDATE = 'CPCS_GPCONNECT_UPDATE';
//Controls whether Clinical pathway can update the GP details held for a patient using NHS GP Connect Update.
export const PFCPC_GPCONNECT_UPDATE = 'PFCPC_GPCONNECT_UPDATE';
export const GP_CONNECT_ACCESS = 'GPCONNECT_ACCESS';
export const NEW_CPCS = 'NEW_CPCS';
export const WALK_IN_PF = 'WALK_IN_PF';
export const DMS = 'DMS';
export const MULTI_SITE = 'MULTI_SITE';

export type Roles =
  | typeof CEGEDIM_USER
  | typeof LOCUM
  | typeof STANDALONE
  | typeof TESTER
  | typeof CPCS
  | typeof MI_FHIR
  | typeof NMS
  | typeof FLUVAC
  | typeof HCF
  | typeof PCS
  | typeof PCS_NoMYS
  | typeof HCF_MYS
  | typeof PCS_GPCONNECT_UPDATE
  | typeof HCF_GPCONNECT_UPDATE
  | typeof CPCS_GPCONNECT_UPDATE
  | typeof PFCPC_GPCONNECT_UPDATE
  | typeof GP_CONNECT_ACCESS
  | typeof NEW_CPCS
  | typeof WALK_IN_PF
  | typeof MULTI_SITE
  | typeof DMS;
