import React, { useContext, useState, useMemo, ReactNode, Dispatch, createContext } from 'react';
import { GlobalStateType, GlobalState } from '@models/common/GlobalStateType';

interface ApplicationContext {
  globalState: GlobalStateType;
  setGlobalState: Dispatch<GlobalStateType>;
}

const ApplicationStateContext = createContext<ApplicationContext | null>(null);

export const ApplicationStateProvider = ({ children }: { children: ReactNode }) => {
  const [globalState, setGlobalState] = useState<GlobalStateType>(new GlobalState());

  const value = useMemo(
    () => ({
      globalState,
      setGlobalState,
    }),
    [globalState],
  );

  return <ApplicationStateContext.Provider value={value}>{children}</ApplicationStateContext.Provider>;
};

export const useApplicationState = () => {
  const context = useContext(ApplicationStateContext);
  if (!context) {
    throw new Error('useApplicationState must be used within a ApplicationStateProvider');
  }
  return context;
};
